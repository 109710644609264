<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action </vs-th>
                <vs-th sort-key="code">PO Data </vs-th>
                <vs-th sort-key="code">PO Type</vs-th>
                <vs-th sort-key="code">Warehouse</vs-th>
                <vs-th sort-key=""></vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                    <vs-td></vs-td>
                    <vs-th sort-key="">PO Value</vs-th>
                    <vs-th sort-key="">GR and Invoice Data</vs-th>
                    <!-- <vs-th sort-key="">Invoice Data</vs-th> -->
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-button v-if="$store.getters['user/hasPermissions']('view')" size="small" color="grey" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 2px" @click="handleEdit(tr.id, 'detail')" />
                            <vs-button v-if="$store.getters['user/hasPermissions']('view')" size="small" color="warning" icon-pack="feather" icon="icon-printer"
                                style="margin-right: 2px" title="Print Preview" @click="handleDetail(tr.id,false)" />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.code }}</div>
                            <div v-if="tr.external_code">External Code : {{ tr.external_code }}</div>
                            <div>Date : {{ formatDate(tr.date) }}</div>
                            <div>Term : {{ tr.payment_term_name }}</div>
                            <div>Eta : {{ formatDate(tr.eta) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div>{{ tr.purchase_type }}</div>
                        <div v-if="tr.so_code">SO Code : {{ tr.so_code }}</div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.warehouse.code }}</div>
                            <div>Name : {{ tr.warehouse.name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                        </div>
                    </vs-td>
                    <vs-td></vs-td>
                    <vs-td key="poValue">
                        <div class="flex flex-col content-center">
                            <div>Currency : {{ tr.currency_code }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Discount</span>{{ formatLocalNumber(tr.discount) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Charge</span>{{ formatLocalNumber(tr.total_charge) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Sub Total</span>{{ isNaN((tr.total - tr.tax)) ? 0 : formatLocalNumber((tr.total - tr.tax)) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Sub Total</span>{{ isNaN((tr.total - tr.tax)) ? 0 : formatLocalNumber((tr.total - tr.tax)) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Tax</span>{{ formatLocalNumber(tr.tax) }}</div>
                            <div v-if="tr.purchase_type.name === 'Bonus'||tr.purchase_type.name === 'Merchandise'" style="text-decoration: line-through;" class="d-inline-flex-b"><span>Total</span>{{ formatLocalNumber(tr.total) }}</div>
                            <div v-else class="d-inline-flex-b"><span>Total</span>{{ formatLocalNumber(tr.total) }}</div>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="tr.gr_data && tr.gr_data.length > 0">
                            <div>Total GR : {{ tr.gr_data.length }}</div>
                            <vs-button color="primary" type="line" title="detail" @click="handleProP(indextr)">See detail</vs-button>
                            <!-- <div :key="idx" v-for="(gr, idx) in tr.gr_data" class="flex flex-col content-center">
                                <div>Code : {{ gr.gr_document }}</div>
                                <div>Date : {{ formatDate(gr.gr_date) }}</div>
                                <div>Shipment : {{ gr.gr_shipment }}</div>
                                <div>Delvr Note : {{ gr.gr_note }}</div>
                                <hr>
                            </div> -->
                        </div>
                    </vs-td>
                    <!-- <vs-td> 
                        <div v-if="tr.gr_data.length > 0">
                            <div :key="idx" v-for="(gr, idx) in tr.gr_data" class="flex flex-col content-center">
                                <div v-if="gr.inv_id">
                                    <div>Code : {{ gr.inv_code }}</div>
                                    <div>Date : {{ formatDate(gr.gr_date) }}</div>
                                    <div>InternalCode : {{ gr.inv_internal_code }}</div>
                                </div>
                                <div v-else>No Invoice Data</div>
                                <hr>
                            </div>
                        </div>
                        <div v-else class="flex flex-col content-center">
                            <div>No Invoice Data</div>
                        </div>
                    </vs-td> -->
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />

        <vs-prompt
            class="customPromptPO"
            @close="activeProP=false"
            :active.sync="activeProP"
            :title="'Good Receives and Invoice data'">
            <div class="con-exemple-prompt">
                <div>
                    <vs-table stripe :data="this.shownGr">
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Date</vs-th>
                            <!-- <vs-th>Shipment</vs-th> -->
                            <vs-th>Delvr Note</vs-th>                            
                            <vs-th>Reversal Status</vs-th>
                            <vs-th>Invoice Code</vs-th>
                            <vs-th>Internal Code</vs-th>
                        </template>

                        <template>
                            <vs-tr :key="indextr" v-for="(tr, indextr) in this.shownGr" >
                                <vs-td>{{ tr.gr_document }} <span><vs-button style="display: inline-block; margin-left: 10px;" color="grey" size="small" icon-pack="feather" icon="icon-eye" title="Detail GR" @click="handleProP1(tr.gr_document, tr.gr_line)" /></span></vs-td>
                                <vs-td>{{ formatDate(tr.gr_date) }}</vs-td>
                                <!-- <vs-td>{{ tr.gr_shipment }}</vs-td> -->
                                <vs-td>{{ tr.gr_note }}</vs-td>
                                <vs-td>{{ (tr.status_reversal == 1) ? 'Reversed' : '-' }}</vs-td>
                                <vs-td v-if="tr.inv_id">{{ tr.inv_code }}</vs-td>
                                <vs-td v-else >No Invoice Data</vs-td>
                                <vs-td v-if="tr.inv_id">{{ tr.inv_internal_code }}</vs-td>
                                <vs-td v-else ></vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-prompt>

        <vs-prompt
            class="customPromptPO1"
            @close="activeProP1=false"
            :active.sync="activeProP1"
            :title="titleProp1">
            <div class="con-exemple-prompt">
                <div>
                    <vs-table stripe :data="this.shownGrLine">
                        <template slot="thead">
                            <vs-th>SKU Code</vs-th>
                            <vs-th>Item Name</vs-th>
                            <vs-th>Received</vs-th>
                            <vs-th>Checked Status</vs-th>
                        </template>

                        <template>
                            <vs-tr :key="indextr" v-for="(tr, indextr) in this.shownGrLine" >
                                <vs-td>{{ tr.purchase_order_line.sku_code }}</vs-td>
                                <vs-td>{{ tr.purchase_order_line.item_name }}</vs-td>
                                <vs-td class="text-right">{{ tr.quantity }} {{ tr.unit_name }}</vs-td>
                                <vs-td>{{ checkedStatus(tr.is_damaged) }}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-prompt>
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        poTypeId: {
            type: String,
        },
        supplierId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Draft",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
            timeOuts: null,
            activeProP: false,
            activeProP1: false,
            titleProp1: "",
            shownGr: [],
            shownGrLine: [],
            data: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/closed`, {
                    params: {
                        limit: this.table.length,
                        page: this.table.page,
                        query: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        warehouse_id: this.sourceWarehouseId,
                        supplier_id: this.supplierId,
                        po_type_ids: this.poTypeId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    console.log(resp.data.rows)
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_rows;
                        this.table.totalPage = resp.data.total_pages;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.limit;
                        this.table.data = resp.data.rows ? resp.data.rows : [];
                        this.data = this.table.data;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id, mode) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-edit",
                params: { id: id, mode: mode},
            });
        },
        handleDetail(id, print) {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-detail",
                params: { id: id },
                query: {print:print},
            });
        },
        handleProP(index) {
            this.activeProP = true
            this.shownGr = this.data[index].gr_data
        },
        handleProP1(grCode, data) {
            this.activeProP1 = true
            this.titleProp1 = 'GR ' + grCode
            this.shownGrLine = data.filter(item => item.is_damaged <= 2)
            console.log(this.shownGrLine)
        },
        checkedStatus(val){
            switch (val) {
                case 0 :
                    return "Good"
                case 1 :
                    return "Damaged"
                case 2 :
                    return "Expired"
                default :
                    return val
            }
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
    },
    mounted() { 
        this.$store.dispatch("user/getPermissions", "purchasing-po")
    },
    watch: {
        // sourceWarehouseId() {
        //     this.getData();
        // },
        // transactionDate() {
        //     this.getData();
        // },
        // status() {
        //     this.getData();
        // },

        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
    },
};
</script>

<style>
.d-inline-flex-b{
    display: inline-flex; 
    justify-content: space-between;
}

.customPromptPO .vs-dialog{
    min-width: 45% !important;
    max-width: fit-content;
}

.customPromptPO .vs-dialog .vs-dialog-text .con-exemple-prompt{
    max-height: 600px;
    overflow: auto;
}

.customPromptPO .vs-dialog footer{
    display: none;
}

.customPromptPO.con-vs-dialog {
    z-index: 52005 !important;
}

.customPromptPO1 .vs-dialog{
    min-width: 45% !important;
    max-width: fit-content;
}

.customPromptPO1 .vs-dialog .vs-dialog-text .con-exemple-prompt{
    max-height: 600px;
    overflow: auto;
}

.customPromptPO1 .vs-dialog footer{
    display: none;
}

.customPromptPO1.con-vs-dialog {
    z-index: 62005 !important;
}
</style>
  