<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <vs-button class="mr-2" color="primary" icon-pack="feather" icon="icon-plus" v-if="$store.getters['user/hasPermissions']('create')" @click="$refs.file.click()">New Import</vs-button>
            <input type="file" ref="file" accept=".xls,.xlsx" @change="handleImport" class="d-none" :key="fileKey" />
        </div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Log Status</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect :options="this.statusOptions" label="name" track-by="name" @selected="(val) => {
                        this.status = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/warehouses" label="codename" track-by="codename" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/suppliers" label="codename" track-by="codename" @selected="(val) => {
                        this.selectedSupplier = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>PO Type</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <POType :value="selectedPoType" base-url="/api/wms/v1/wms/purchase-type" 
                        label="name" 
                        track-by="name" 
                        :multiple="true" 
                        :allowEmpty="false"
                        @selected="(val) => {
                            this.selectedPoType = val;
                        }">
                    </POType>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>External Code</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/log/import/po/external-codes" label="code" track-by="code" @selected="(val) => {
                        this.selectedExCode = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="this.status? this.status.name:''" :date="this.date"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.code : '0'"
                    :supplier-id="(this.selectedSupplier) ? this.selectedSupplier.code : '0'"
                    :external-code="(this.selectedExCode) ? this.selectedExCode.code : '0'"
                    :po-type-id="this.selectedPoTypeIds">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./components/CustomMultiSelect.vue";
import POType from "./components/POType.vue";
import Date from "./components/Date.vue";
import Table from "./components/LogImportTable.vue";
export default {
    components: {
        CustomMSelect,
        POType,
        Date,
        Table
    },
    data() {
        return {
            activeTab: "LogImport",
            fileKey: 0,
            draw: 0,
            date: null,
            selectedSrcWarehouse: null,
            selectedSupplier: null,
            selectedExCode: null,
            selectedPoType: [],
            selectedPoTypeIds: "0",
            status: null,
            statusOptions: [
                {
                    name: "Pending",
                },
                {
                    name: "Imported",
                },
                {
                    name: "Failed",
                }
            ],
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
            console.log("===============")
            console.log(this.selectedPoTypeIds)
        },
        handleImport(e){
            this.$vs.dialog({
                type: "confirm",
                title: `Import from ${e.target.files[0].name}`,
                text: `This action CANNOT be undone, CANCEL to change the file or ACCEPT to continue`,
                accept: () => {
                    this.$vs.loading();
                    var formData = new FormData();
                    formData.append("file", e.target.files[0])
                    this.$http.post(`${this.$store.state.purchaseOrder.baseUrlPath}/import`, formData)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "success",
                                    title: `Success`,
                                    text: resp.message
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "danger",
                                    title: `Unauthorized`,
                                    text: resp.message,
                                });
                            }
                        });
                },
            });
            this.fileKey++
        }
    },
    mounted(){
        this.$store.dispatch("user/getPermissions", "purchasing-po")
        let a = {
            id: 0,
            code: "All",
            name: "All"
        }

        if (this.selectedPoType.length == 0) {
            this.selectedPoType = [a]
        }
        sessionStorage.setItem('activeTabPO', "8");
    },
    watch: {
        selectedPoType(v) {
            var poTypeIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.name) poTypeIds.push(e.name) });
            }
            this.selectedPoTypeIds = poTypeIds.join(",");
        },
    }
}
</script>

<style>
.d-none{
    display: none;
    visibility: hidden;
}
</style>