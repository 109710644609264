<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <!-- <vs-button class="mr-2" color="primary" icon-pack="feather" icon="icon-plus" @click="handleCreate(0)">Create PO Manual</vs-button> -->
            <vs-button class="mr-2" color="primary" icon-pack="feather" icon="icon-plus" v-if="$store.getters['user/hasPermissions']('create')" @click="handleCreate(1)">Create PO - Purchase Price</vs-button>
            <vs-button class="mr-2" color="primary" icon-pack="feather" icon="icon-plus" v-if="$store.getters['user/hasPermissions']('create')" @click="$refs.file.click()">Import PO</vs-button>
            <input type="file" ref="file" accept=".xls,.xlsx" @change="handleImport" class="d-none" :key="fileKey" />
        </div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/warehouses" label="name" track-by="name" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/suppliers" label="codename" track-by="codename" @selected="(val) => {
                        this.selectedSupplier = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>PO Type</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <POType :value="selectedPoType" base-url="/api/wms/v1/wms/purchase-type" 
                        label="name" 
                        track-by="name" 
                        :multiple="true" 
                        :allowEmpty="false"
                        @selected="(val) => {
                            this.selectedPoType = val;
                        }">
                    </POType>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="activeTab" :transaction-date="transactionDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"
                    :supplier-id="(this.selectedSupplier)? this.selectedSupplier.id:0"
                    :po-type-id="this.selectedPoTypeIds"
                    >
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./components/CustomMultiSelect.vue";
import POType from "./components/POType.vue";
import Date from "./components/Date.vue";
import Table from "./components/DraftTable.vue";
export default {
    components: {
        CustomMSelect,
        POType,
        Date,
        Table
    },
    data() {
        return {
            activeTab: "Draft",
            fileKey: 0,
            draw: 0,
            transactionDate: null,
            selectedSrcWarehouse: null,
            selectedSupplier: null,
            selectedPoType: [],
            selectedPoTypeIds: "0"
        }
    },
    methods: {        
        handleCreate(buyingprice) {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName + "-create",
                query: {
                    bp: buyingprice
                }
            });
        },
        handleFilter() {
            this.draw++;
        },
        handleImport(e){
            this.$vs.dialog({
                type: "confirm",
                title: `Import from ${e.target.files[0].name}`,
                text: `This action CANNOT be undone, CANCEL to change the file or ACCEPT to continue`,
                accept: () => {
                    this.$vs.loading();
                    var formData = new FormData();
                    formData.append("file", e.target.files[0])
                    this.$http.post(`${this.$store.state.purchaseOrder.baseUrlPath}/import`, formData)
                        .then((resp) => {
                            this.$vs.loading.close();
                            if (resp.code == 200) {
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "success",
                                    title: `Success`,
                                    text: resp.message
                                });
                            } else {
                                this.$vs.loading.close();
                                this.$vs.dialog({
                                    type: "alert",
                                    color: "danger",
                                    title: `Unauthorized`,
                                    text: resp.message,
                                });
                            }
                        });
                },
            });
            this.fileKey++
        }
    },
    mounted(){
        this.$store.dispatch("user/getPermissions", "purchasing-po")

        let a = {
            id: 0,
            code: "All",
            name: "All"
        }

        if (this.selectedPoType.length == 0) {
            this.selectedPoType = [a]
        }

        sessionStorage.setItem('activeTabPO', "0");
    },
    watch: {
        selectedPoType(v) {
            var poTypeIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) poTypeIds.push(e.id) });
            }
            this.selectedPoTypeIds = poTypeIds.join(",");
        },
    }
}
</script>

<style>
.d-none{
    display: none;
    visibility: hidden;
}
</style>